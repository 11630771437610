.addProduct {
  & h2 {
    margin-bottom: 30px;
  }

  &__form {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;

    @media screen and (max-width: 600px) {
      grid-template-columns: 1fr;
    }
  }

  &__btn {
    display: flex;
    justify-content: center;
    margin-top: 40px;

    & button {
      &:hover {
        background-color: $primary-colorAlt;
        color: #fff;
      }
    }
  }
}
