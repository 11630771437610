.paypalPayment {
  margin-top: 30px;
  background-color: #eae6ed;
  border-radius: 4px;
  padding: 20px;
  font-size: 13px;

  & a {
    color: #337ab7;
  }
  &__header {
    display: flex;
    gap: 10px;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 20px;

    & img {
      width: 200px;
    }
  }

  &__info {
    background-color: #dcd7e3;
    padding: 15px 20px;
    font-weight: 600;
    margin-bottom: 30px;
  }

  &__info2 {
    border-top: 1px solid #cfc8d8;
    padding-top: 30px;
    margin-bottom: 20px;
    font-size: 14px;
  }

  &__btnCon {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    &__btn {
      background: $primary-color;
      width: 200px;
      height: 35px;
      border: none;
      color: #fff;
      font-size: 13px;
      cursor: pointer;
      transition: 0.3s ease-in-out;
      text-transform: uppercase;
      letter-spacing: 3.5px;

      &:hover {
        background: $primary-colorAlt;
      }
    }
  }
}
