.contact {
  &__mainCon {
    padding: 100px var(--body-padding);
    display: flex;
    justify-content: center;
  }
  &__main {
    width: 100%;
    max-width: 900px;
    display: grid;
    grid-template-columns: 2fr 3fr;
    gap: 30px;

    @media screen and (max-width: 650px) {
      grid-template-columns: 1fr;
    }
  }

  &__left {
    & p {
      margin: 0;
      margin-bottom: 10px;
    }
  }

  &__right {
    & input {
      width: 100%;
    }

    &__btn {
      background-color: #61ce70;
      width: 100%;
      height: 50px;
      border: none;
      color: #fff;
      border-radius: 4px;
      font-weight: 600;
      font-size: 16px;
      cursor: pointer;
    }
  }
}
