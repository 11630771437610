.sidebar {
  width: 250px;
  background-color: $primary-colorAlt;
  height: calc(100vh - 80px);
  color: #fff;
  position: fixed;
  left: 0;
  top: 80px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media screen and (max-width: 1000px) {
    display: none;

    &.draw {
      display: flex;
    }
  }

  &__list {
    padding: 0;
    margin: 0;
    margin-top: 30px;

    &__item {
      padding: 20px 30px;
      cursor: pointer;
      transition: 0.3s;
      letter-spacing: 0.5px;

      &:hover {
        background-color: $secondary-color;
      }
      &.active {
        background-color: $secondary-color;
      }
    }
  }

  &__btn {
    width: 150px;
    margin: 0 auto;
    margin-bottom: 20px;
  }
}
