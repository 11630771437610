.footer {
  color: #fff;

  &__main {
    padding: 50px var(--body-padding);
    background-color: $primary-color;

    &__top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 20px;
      padding-bottom: 30px;
      border-bottom: 2px solid #c1cad3;
      margin-bottom: 30px;
      flex-wrap: wrap;

      &__logo {
        height: 80px;
      }

      &__links {
        display: flex;
        align-items: center;
        gap: 20px;
        flex-wrap: wrap;

        &__item {
          display: flex;
          align-items: center;
          gap: 10px;
          font-size: 13px;
          letter-spacing: 0.3px;
        }
      }
    }

    &__bottom {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 30px;
      flex-wrap: wrap;

      &__links {
        display: flex;
        gap: 20px;
        list-style: none;
        padding: 0;
        margin: 0;
        flex-wrap: wrap;

        & li {
          font-size: 12px;
          font-weight: 500;
          text-transform: uppercase;
          letter-spacing: 0.3px;
        }
      }
    }
  }

  &__right {
    background-color: $secondary-color;
    padding: 15px var(--body-padding);
    text-align: center;

    & p {
      padding: 0;
      margin: 0;
      font-size: 11px;
      font-weight: 500;
      text-transform: uppercase;
      letter-spacing: 2px;

      & a {
        color: $primary-color;
      }
    }
  }
}
