.ordersDB {
  &__top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    flex-wrap: wrap;
    margin-bottom: 30px;

    & button {
      &:hover {
        background-color: $primary-color;
        color: #fff;
      }
    }

    &__form {
      height: 50px;
      width: 400px;
      position: relative;

      @media screen and (max-width: 500px) {
        width: 100%;
      }

      & input {
        width: 100%;
        height: 100%;
        border: none;
        padding: 0 20px;
        padding-right: 50px;
        border: 1px solid rgb(217, 217, 217);

        &::placeholder {
          color: rgb(150, 150, 150);
        }

        &:focus {
          outline: none;
        }
      }

      & button {
        position: absolute;
        height: 100%;
        width: 50px;
        right: 0;
        border: none;
        background-color: $secondary-color;
        cursor: pointer;
      }
    }
  }
}
