.headerImg {
  background-image: url(../../../images/hero3.jpeg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  box-shadow: inset 0 0 0 1000px #00000042;
  height: 200px;
  display: flex;
  align-items: center;
  padding: 30px var(--body-padding);

  & h2 {
    color: #ffffff;
    font-size: 35px;
    font-weight: 700;
    text-transform: capitalize;
    letter-spacing: 0.3px;
  }
}
