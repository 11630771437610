.hero {
  //   background-image: url(https://dealsmarineparts.com//wp-content//uploads//2020//09//Banner2.jpg);
  //   background-position: center;
  //   background-size: cover;
  //   background-repeat: no-repeat;
  position: relative;

  &__eachFade {
    & img {
      width: 100%;
      height: 660px;
      object-fit: cover;
      filter: brightness(80%);
    }
  }

  &__main {
    position: absolute;
    bottom: 120px;
    left: var(--body-padding);
    color: #fff;
    z-index: 2;

    & h2 {
      font-size: 15px;
      font-weight: 500;
      text-transform: uppercase;
      letter-spacing: 3.5px;
      margin-bottom: 10px;
    }

    & h1 {
      font-size: 45px;
      font-weight: 600;
      text-transform: uppercase;
      letter-spacing: 0.3px;
      max-width: 650px;
      margin-bottom: 20px;

      @media screen and (max-width: 500px) {
        font-size: 35px;
      }
    }

    &__buttons {
      display: flex;
      align-items: center;
      gap: 20px;
      flex-wrap: wrap;
    }
  }
}
