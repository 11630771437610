.account {
  &__main {
    padding: 60px var(--body-padding);
    font-size: 14px;
    font-weight: 500;

    &__details {
      margin-bottom: 20px;
      & h3 {
        font-size: 25px;
        margin-bottom: 20px;
      }

      &__info {
        &__item {
          margin-bottom: 10px;
          display: flex;
          align-items: center;
          gap: 30px;
        }
      }
    }

    &__logout {
      margin-bottom: 40px;
      & button {
        height: 35px;
        width: 100px;

        &:hover {
          background-color: $primary-colorAlt;
        }
      }
    }

    &__order {
      & h3 {
        font-size: 25px;
        margin-bottom: 20px;
      }

      &__table {
        overflow: auto;
        padding-bottom: 20px;

        &__body {
          display: flex;
          flex-direction: column;
          min-width: 980px;
        }

        &__row {
          padding: 20px 0;
          display: flex;
          justify-content: space-between;
          gap: 10px;

          &.head {
            border-bottom: 1px solid rgb(184, 184, 184);
          }
          //   &:not(:last-child) {
          //     border-bottom: 1px solid rgb(184, 184, 184);
          //   }

          &__head {
            font-weight: 700;
            font-size: 14px;
          }

          &__data {
            font-weight: 400;
            font-size: 15px;
            line-height: 20px;

            & h4 {
              font-weight: 700;
            }
          }
        }
      }
    }
  }
}
