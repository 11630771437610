.homeDB {
  &__top {
    display: grid;
    grid-template-columns: 2fr 2fr 3fr;
    gap: 5%;

    @media screen and (max-width: 600px) {
      grid-template-columns: 1fr;
    }

    &__card {
      border-bottom: 3px solid $primary-color;
      box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
        rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
      border-radius: 5px;
      padding: 20px;

      & p {
        text-align: right;
        font-size: 25px;
        padding: 0;
        margin: 0;
        margin-top: 20px;
      }

      & h4 {
        font-size: 18px;
      }

      &__type {
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
        font-size: 25px;
        gap: 10px;

        & span {
          font-size: 15px;
          font-weight: 500;
        }
      }
    }
  }

  &__users {
    margin-top: 80px;
    & h3 {
      font-size: 25px;
      margin-bottom: 20px;
    }

    &__table {
      overflow: auto;
      padding-bottom: 20px;

      &__body {
        display: flex;
        flex-direction: column;
        min-width: 900px;
      }

      &__row {
        padding: 20px 0;
        display: flex;
        justify-content: space-between;
        gap: 10px;

        border-top: 1px solid rgb(219, 219, 219);
        &.head {
          border: none;
        }
        //   &:not(:last-child) {
        //     border-bottom: 1px solid rgb(184, 184, 184);
        //   }

        &__head {
          font-weight: 700;
          font-size: 14px;
        }

        &__data {
          font-weight: 400;
          font-size: 15px;
          line-height: 20px;

          & h4 {
            font-weight: 700;
          }

          &__btn {
            width: 100px;
            height: 35px;
            border: none;
            background-color: $primary-color;
            color: #fff;
            cursor: pointer;
          }
        }
      }
    }
  }
}
