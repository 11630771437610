.dashboardLayout {
  &__topbar {
    width: 100vw;
    background-color: $primary-colorAlt;
    height: 80px;
    color: #fff;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 30px;
    gap: 20px;

    &__logo {
      height: 60px;
    }

    &__info {
      display: flex;
      align-items: center;
      gap: 20px;

      &__text {
        @media screen and (max-width: 500px) {
          display: none;
        }
      }

      & button {
        width: 80px;
        min-width: 120px;
        height: 35px;
      }
    }
  }

  &__main {
    padding-top: 110px;
    padding-left: 280px;
    padding-right: 30px;

    @media screen and (max-width: 1000px) {
      padding-left: 30px;
    }
  }
}
